body {
  background-color: #f2f2f2;
  background-image: url('./images/bg-black-stone.jpeg');
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: '100%';
}

#root {
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

.joinHeading {
  text-align: left;
  font-family: "Libre Baskerville", Georgia, "Times New Roman", serif;
  font-size: 30px;
  position: absolute;
  top: 0px;
  left: 15px;
  color: black;
  width: 70%;
}

@media (max-width: 950px) {
  .joinHeading {
    font-size: 20px;
  }
}

@media (max-width: 750px) {
  .joinHeading {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .joinHeading {
    font-size: 10px;
  }
}

.videoCalc {
  display: block;
  width: 100%;
  align-items: center;
}

@media (min-width: 1200px) {
  .videoCalc {
    display: flex;
  }
}

.recruitHeader {
  color: #F1F1E6;
  font-size: 5em;
  font-family: Maven Pro, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  line-height: 0.7em;
  /* margin-left: -40px!important; */
  /* z-index: 1; */
  position: relative;
}

.recruitSub {
  color: black;
  font-size: 1.2em;
  font-family: Maven Pro, Helvetica, Arial, Lucida, sans-serif;
  font-weight: bold;
  margin-top: -80px;
}

.agentEmailHeader {
  font-size: 1.5em;
  background-color: white;
  color: black;
  padding: 20px;
}

@media (max-width: 950px) {
  .recruitHeader {
    font-size: 5em;
  }

  .recruitSub {
    font-size: 2em;
    margin-top: -60px;
  }

  .agentEmailHeader {
    font-size: 1.3em;
  }
}

@media (max-width: 750px) {
  .recruitHeader {
    font-size: 4em;
  }

  .recruitSub {
    font-size: 1.5em;
    margin-top: -40px;
  }

  .agentEmailHeader {
    font-size: 1.2em;
  }
}

@media (max-width: 500px) {
  .recruitHeader {
    font-size: 2em;
  }

  .recruitSub {
    font-size: 1.2em;
    margin-top: -20px;
  }

  .agentEmailHeader {
    font-size: 1em;
  }
}

.recruitInput {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 8px;
}

/* TextField styling */
:root {
  --lightGrey: #f2f2f2;
  --grey: #9da4ad;
  --darkGrey: #3c3d3f;
  --focusBlue: #0a53e4;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  border-radius: 4px;
}

.input-container .filled {
  transform: translate(0, -8px) scale(0.8);
  background-color: var(--lightGrey);
  padding: 0 4px;
  font-size: 12px;
}

.input-container textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  border: none;
  padding: 8px;
  font-size: 14px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: transparent;
  font-family: Maven Pro, Helvetica, Arial, Lucida, sans-serif;
}

.input-container input {
  height: 32px;
}

.input-container:hover {
  box-shadow: 0 0 0 1px var(--darkGrey);
}

.input-container:focus-within {
  box-shadow: 0 0 0 2px var(--focusBlue);
}